<template>
  <v-container>
    <v-row >
      <v-col cols="12">
        <v-card>
          <v-toolbar align-center color="color1 color1Text--text">
            <v-toolbar-title align-center><h1>Privacy Policy</h1></v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p>
              Effective: December 1st, 2017
            </p>
            <h1 class="pb-3">VolleyballLife Privacy Policy</h1>

            <h2>Overview</h2>
            <p>
              This Privacy Policy describes your privacy rights regarding the collection, use, storage, sharing,
              and protection of information (including your personal information) collected about you on
              volleyballlife.com and www.volleyballlife.com (“Sites”) by Mohawk Man Technologies Inc.
              d/b/a Volleyball Life (“VolleyballLife”). It applies to the VolleyballLife.com website and all
              related websites, applications, services, and tools (“Sites and Services”) where this policy is
              referenced, regardless of how you access or use them. By using our Sites and Services, you agree
              to the terms of this Privacy Policy and expressly consent to the processing of your personal
              information as described in this policy.
            </p>

            <h2>Non-US Users</h2>
            <p>
              If you are located outside of the United States, please note that the information you provide to us
              will be transferred to the United States, where laws regarding processing of personal information
              may be less stringent than the laws in your country. By using the Sites and Services, you consent
              to this transfer.
            </p>

            <h2>Collection and use of personal information and other information</h2>
            <p>
              Throughout this Privacy Policy, we use the term &quot;personal information&quot; to describe information
              that can be associated with a specific person and can be used to identify that person. We do not
              consider personal information to include information that has been made anonymous or
              aggregated so that it can no longer be used, whether in combination with other information or
              otherwise, to identify a specific person. In order to provide you with our services we ask you to
              provide us with some personal information. For an individual user, the minimum amount of
              information we collect is your email address, a password. In most cases your name is required as
              well. If you register as a coach or tournament director, we may also collect country location,
              time zone, team name, sport type, and contact information of the members of your team. This
              information about your team or tournament will be associated with your personal information.
            </p>
            <p>
              We may receive personal information from third parties that provide services for us in
              connection with the Sites and Services, and VolleyballLife may associate this information with
              the personal information we already have. We may receive personal information (including your
              email address) from a current user of the Sites and Services.
            </p>

            <h2>Examples of optional information that you or members of your team may provide include:</h2>
            <ul>
              <li>Your contact information (e.g., phone number, physical address, etc.)</li>
              <li>Sports specific information (e.g., your AAU number, AVP number, etc.)</li>
              <li>Schedule information (e.g., whether you plan to attend an upcoming event)</li>
              <li>Location information (e.g., your physical location at a given time)</li>
              <li>Device and software information (e.g., the type of mobile device and associated hardware and software information, the browser used to access our web service, etc.)</li>
            </ul>
            <p>
              If you are the parent or guardian of a player who is using VolleyballLife, we may ask you for
              such optional information about your child. The service is not intended for use by children under
              13 and we never knowingly collect personal information from children under 13.
            </p>
            <p>
              You may need to pay for your use of our Sites and Services using a payment card (e.g., credit or
              debit card). We use third party payment card processing companies to process payments in
              connection with the Sites and Services. These third parties have committed to VolleyballLife to
              process your payment card information in a secure, PCI compliant manner. Your payment card
              information is processed and retained by these third parties and will not be passed on to
              VolleyballLife; VolleyballLife does not store your payment card information.
            </p>
            <p>
              If you are a coach or Tournament director, you also may use VolleyballLife to collect funds from other users. We may deduct a payment processing fee from these fund transfers, but we will always spell out any applicable payment processing fees. In addition, we will not use payment information you have provided for fund transfers to charge you for other services.  The fund transfers do not flow through VolleyballLife and are accomplished using Stripe, PayPal or another third party payment system, which is subject to separate terms with that third party (please review the section of this policy entitled “How We Share Your Information” below for more information).
            </p>

            <h2>Use of cookies, image tags, and device identifiers</h2>
            <p>
              Cookies are small amounts of information stored in files within your computer's browser. Device identifiers unique numbers used to identify devices like mobile phones.  We may use these technologies to collect information like IP address and user agent information (browser version, OS type and version). We may use session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer until you delete them) or access device identifiers to store your preferences, identify you and maintain your signed-in status, gather information regarding the date and time of your visit to the VolleyballLife website and the information for which you searched and which you viewed, and provide you with a more personal and interactive experience, but we do not store your username or password or your personal information in a cookie. We may also use web beacons which are small graphic objects or pixels that are embedded in a web page. We offer certain features that are available only through the use of cookies or device identifiers. You are always free to decline cookies if your web browser permits. However, if you decline cookies, you may not be able to use certain VolleyballLife features.
            </p>
            <p>
              Use of certain technology designed to prevent cookies or other advertising related technologies (such as ad blocking software) may interfere with the correct operation of the VolleyballLife website and services.
            </p>
            <p>
              We may use or access our cookies or web beacons on our website or third party websites to serve ads to you in a more personalized fashion.  We let other companies (including data providers, advertising networks, advertisers) on our Services set and access their cookies or web beacons on your computer or access your device identifiers.  These companies use these technologies to enable you to receive customized ads or content through web, mobile or other media channels.  Other companies' use of their cookies and web beacons and of device identifiers is subject to their own privacy policies, not this one. These companies do not have access to our cookies except as described under “Advertising,” below.
            </p>

            <h2>Do Not Track Settings</h2>
            <p>
              Your web browser may enable you to indicate your preference as to whether you wish to allow websites and apps to collect personal information about your online activities over time and across different websites or online services (for example, through “do not track” settings of the browser). We believe that there is currently no consistent industry standard for how to interpret and respond to these web browser preference settings. Consequently, at this time our Sites and Services do not respond to the preferences you may have set in your browser regarding the collection of such personal information, and we may continue to collect personal information in the manner described in this Privacy Policy. In addition, other parties may collect personal information about your online activities over time and across different Websites when you use our Sites and Services. This policy does not apply to and we are not responsible for those other parties. Please see the section of this policy entitled “How We Share Your Information” for more detail on how we interact with third parties with respect to your information, including with respect to targeted advertising
            </p>
            <p>
              If you provide feedback to us, we may use and disclose such feedback for any purpose, provided we will not associate such feedback with your personal information.  We will treat the personal information in the feedback in accordance with this Privacy Policy.
            </p>

            <h2>Use of personal information</h2>
            <p>
              As a general rule we use your personal information solely to provide the Services, but we don’t usually look at your information. However, during the process of developing, troubleshooting and administering the Site and Services, it may be necessary to look at pieces of your information to improve the Services and user experience, offer you additional information, opportunities and functionality, respond to requests that you make, resolve a problem or dispute, investigate potential security issues, or verify your compliance with our terms of use. Also, when you write us to request support, our customer support representatives may look at your information in order to assist you. We may also access and use your information to compare information for accuracy and verify it with third parties or to comply with applicable law or legal process.
            </p>
            <p>
              Third parties (such as (i) partner, coach or managers of each team/group or (ii) the tournament director, league or club managers in each club or league) can upload your personal information to the Services and share pieces of your personal information and other information with other team/club or league members through team/club and league settings or with the public under the Public Access settings. It is up to you as to whether you share this optional information -- none of it is required, but you may need to work with your team/club owner or manager or tournament director/league owner/commissioner/manager to limit any information sharing or to remove or update any information submitted on your behalf.  We may also share your information with a team/club owner or manager, or if you have signed up to a team that participates in our clubs or leagues products, a club/league owner, tournament director, commissioner or manager, or their designated contacts, in each case as shown on our records, if we believe you or another user that has interacted with you through our services may have violated our terms of use as we consider necessary to help address the situation.
            </p>

            <h2>Monitoring user activity</h2>
            <p>
              We may monitor user activity on our systems in connection with user compliance with our terms of use, supporting users, and developing and improving the Sites and Services.
            </p>

            <h2>How We Share Your Information</h2>
            <p>
              VolleyballLife will not sell, rent or lease your personal information to others except as described in this policy. VolleyballLife shares personal information in the following ways:
            </p>
            <ul>
              <li>
                VolleyballLife employs various third-parties to run its service, as further described below.  These are trusted partners who operate components of the VolleyballLife architecture.  VolleyballLife relies on these third parties to maintain the safety and security of your information.
              </li>
              <li>
                We may share your personal information with third party service providers to:  provide you with the Sites and Services that we offer you; to conduct quality assurance testing; to facilitate creation of accounts; to provide technical support; and/or to provide other services to VolleyballLife or to you.
              </li>
              <li>
                VolleyballLife may engage data providers who may collect certain web log data to enable you to receive customized ads or content through web, mobile or other media channels.
              </li>
              <li>
                VolleyballLife works with various third parties to bring you additional goods and services through our Sites and Services (e.g., equipment or apparel sales).  We may share personal information and transaction data (excluding payment card numbers) with such third-parties if you, your team manager or your league/club commissioner has indicated interest in such third parties’ goods or services.  Such third parties may also collect personal information from you if you decide to interact with them.  Information shared with or collected by such third parties is subject to their privacy policy. We do not control the privacy policies of these third parties and we encourage you to review their privacy policies.
              </li>
              <li>
                We may share some or all of your personal information in connection with or during negotiation of any merger, financing, acquisition or dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets.  In the event of an insolvency, bankruptcy, or receivership, personal information may also be transferred as a business asset. If another company acquires our shares, business, or assets, that company will possess the personal information collected by us and will assume the rights and obligations regarding your personal information as described in this Privacy Policy. Although we currently do not have a parent company, any subsidiaries, joint ventures, or other companies under common control with us (collectively, “Affiliates”), we may in the future.  You hereby agree that we may share some or all of your personal information with these Affiliates.  We will require our Affiliates to comply with this Privacy Policy.
              </li>
              <li>
                Regardless of any choices you make regarding your personal information, we may disclose personal information (a) in connection with any legal investigation; (b) to comply with relevant laws or to respond to subpoenas, warrants or other legal process served on us; (c) to protect or defend the rights or property of VolleyballLife or users of the Site and Services; and/or (d) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or our Terms of Service.
              </li>
            </ul>

            <h2>Advertising</h2>
            <p>
              We use third-parties to help measure our content and advertising effectiveness and to serve ads on our behalf and on behalf of other third-parties.  These ads are in some cases targeted to you based on personal information collected across other websites and are shown on our website and on third party websites. We may share some anonymous information (for example, your role as a coach or participant in the group with which you are involved or the sports or activities with which you are involved), as well as anonymous, aggregated demographic information.  These third parties may place cookies or web beacons on our websites or access your device identifiers.  These third parties have committed to not include any personal information in these cookies.  VolleyballLife may share your email address with such third parties, solely in a hashed form that does not, in itself, identify you. To opt-out of these data provider cookies, please visit http://www.aboutads.info/choices or http://www.networkadvertising.org/choices.
            </p>
            <p>
              Advertisers (including ad serving companies) may assume that people who interact with, view, or click on targeted ads meet the targeting criteria (for example, men ages 25-38 from a particular geographic area with interest in sports). By interacting with or viewing an ad you are consenting to the possibility that the advertiser will assume that you meet the targeting criteria used to display the ad.
            </p>
            <p>
              We also access non-personal information from third parties to improve the VolleyballLife Sites and Services.  For example, we access information about your location, determined from your IP address or mobile device location information, to personalize your experience. We may use this information to provide you with weather or other information that pertains to your location.
            </p>
            <p>
              We may also access information from third-parties that provide analytical data.  Our primary analytical service is Google Analytics.  We use that information to improve the effectiveness of VolleyballLife and make the service easier to use.  We analyze the data in the aggregate, rolling up the data across many users to better understand how VolleyballLife is being used at a macro level.  You can access Google’s privacy policy at the following link:  http://www.google.com/intl/en/policies/privacy/.  You can opt out of Google Analytics here: http://tools.google.com/dlpage/gaoptout?hl=en.
            </p>

            <h2>Children's Privacy</h2>
            <p>
              VolleyballLife does not knowingly collect information from children under the age of 13.  Furthermore, we do not target our service to children under these ages nor do we knowingly communicate with children under 13. When we work with teams and groups with children under 13, the parents / guardians of the children are responsible for entering and managing their children’s information or authorizing others to do so.
            </p>

            <h2>Security and Retention</h2>
            <p>
              The security of your information is important to us. We store your personal information in VolleyballLife’s systems and we use reasonable security precautions to protect it.
            </p>
            <p>
              No method of electronic transmission or storage is 100% secure, however. Therefore, we cannot guarantee the security of your personal information or other information. If you have any questions or concerns about security, drop us a line at admin@VolleyballLife.com and we will do our best to respond to them.
            </p>
            <p>
              We retain information as long as it is necessary and relevant for our operations. Typically we retain information submitted until it is deleted by the user or team/group owner/manager so that it may be accessed for that team or group on an ongoing basis (for example in the next sports season). In addition, we may retain personal information from closed accounts to comply with the law, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigation, enforce our Terms of Service and take other actions permitted by law.
            </p>

            <h2>Data Deletion</h2>
            <p>
              If you would like any data associated with your user to be deleted, please send your request to support@volleyballlife.com.
            </p>

            <h2>Third Party Sites</h2>
            <p>
              Our Sites and Services may integrate with or contain links to other websites and services.  This is for your convenience and does not signify our endorsement of such other websites or location or their content. For example, you may be able to order team or group themed merchandise through one of our partners. Those third party sites and services have their own privacy statements and terms of conditions that we do not control, and our privacy policy does not apply to these third party sites and services. We encourage you to review these statements and terms and contact the third party with any questions. We do not review and are not responsible for the privacy practices or other actions of these third parties.
            </p>

            <h2>Notice to California Residents - Your California Privacy Rights</h2>
            <p>
              A California resident who has provided personal data to a business with whom he/she has established a business relationship for personal, family, or household purposes (a “California Customer”) may request information about whether the business has disclosed personal information to any third parties for the third parties’ direct marketing purposes.  In general, if the business has made such a disclosure of personal data, upon receipt of a request by a California Customer, the business is required to provide a list of all third parties to whom personal data was disclosed in the preceding calendar year, as well as a list of the categories of personal data that were disclosed.  California Customers may request further information about our compliance with this law by e-mailing the address specified below in “Questions”.  Please note that we are required to respond to one request per California Customer each year and we are not required to respond to requests made by means other than through this e-mail address.
            </p>
            <p>
              S.B. 568, titled “Privacy Rights for California Minors in the Digital World” allows California minors a right to delete content that they posted to a website, social media profile, or online service while under the age of 18. To request removal of any such content, please contact us at admin@VolleyballLife.com.
            </p>

            <h2>Changes</h2>
            <p>
              If we decide to change this privacy policy, we will post the changes on this site, and if there is a material change to our privacy policy for any reason, we will notify you promptly by sending an email to an email address we have on file for you.
            </p>

            <h2>Questions?</h2>
            <p>
              If you have any questions about this Privacy Policy, just send an email to admin@VolleyballLife.com.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>
ul {
  margin: 10px 0px 10px 40px;
}
</style>
